






















































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { Apiary, Hive, UserDetails } from '@/types';
import {
  Vue, Component,
} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Certificate extends Vue {
  userDetails: UserDetails | null = null
  apiary: Apiary | null = null
  hive: Hive | null = null

  created() {
    const debugPrefix = 'Certificate: created()';
    console.log(debugPrefix); // eslint-disable-line no-console
  }

  async mounted() {
    const debugPrefix = 'Certificate: mounted()';

    console.log(`${debugPrefix} - this.$route.params =`, this.$route.params); // eslint-disable-line no-console

    this.userDetails = await this.$store.dispatch('getUserById', {
      userId: this.$route.params.userId,
    });

    this.apiary = await this.$store.dispatch('getApiaryById', {
      apiaryId: this.$route.params.apiaryId,
    });

    this.hive = await this.$store.dispatch('getHiveById', {
      apiaryId: this.$route.params.apiaryId,
      hiveId: this.$route.params.hiveId,
    });
  }

  get ready(): boolean {
    return this.userDetails !== null && this.apiary !== null && this.hive !== null;
  }

  get fullName(): string {
    return `${this.userDetails?.firstName} ${this.userDetails?.lastName}`;
  }

  get apiaryName(): string {
    return this.apiary?.name ?? 'Sconosciuto';
  }

  get apiaryLocation(): string {
    return this.apiary?.location ?? 'Sconosciuta';
  }

  get apiaryBeekeeperName(): string {
    return this.apiary?.beekeeper.name ?? 'Sconosciuto';
  }

  get hiveName(): string {
    return this.hive?.name ?? '';
  }

  get hivePopulation(): string {
    return this.hive?.population ?? 'Sconosciuta';
  }

  get hiveBehavior(): string {
    return this.hive?.behavior ?? 'Sconosciuto';
  }

  get hiveProductivity(): string {
    return this.hive?.productivity ?? 'Sconosciuta';
  }
}
